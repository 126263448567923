import React from 'react';
import MainLayout from 'components/layouts/MainLayout'
import CompanyLogo from "components/logos/CompanyLogo"
import { StoreContext } from 'store';

const environment_iframe_map = {
    stage: 'https://plan.webapp.stage.forsightdigital.com/department-calculator',
    prod: 'https://plan.webapp.prod.forsightdigital.com/department-calculator',
    // local: 'http://192.81.130.105:30013/department-calculator',
    default: 'https://plan.webapp.stage.forsightdigital.com/department-calculator',
}

const iFrameID = 'department-calculator-iframe'

function AppFastplan({ data }) {

    const environment = process.env.GATSBY_ENVIRONMENT

    const store = React.useContext(StoreContext)
    const iFrameSrc = environment_iframe_map[environment] || environment_iframe_map.default

    // const adjustIFrameHeight = (height) => {
    //     if (typeof document === `undefined`) return
    //     const iFrame = document.getElementById(iFrameID);
    //     if(iFrame) {
    //         // iFrame.height = "";
    //         iFrame.height = iFrame.height = height;
    //     } 
    // }
    // React.useEffect(() => {
    //     adjustIFrameHeight()
    //     function receiveMessage(event) {
    //         let height = "800px"
    //         if (event.data.key !== 'start') {
    //             height = "1000px"
    //         }
    //         adjustIFrameHeight(height)
    //     }
    //     if ('addEventListener' in window) {
    //         window.addEventListener('message', receiveMessage, false);
    //     } else if ('attachEvent' in window) { //IE
    //         window.attachEvent('onmessage', receiveMessage);  
    //     }
    // }, [])


    return (
        <MainLayout>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{margin: '0 auto', textAlign: 'center', }}>
                <a href="https://forsight.forsightdigital.com" target="_blank" style={{display: "inline-block"}}>
                    <div style={{
                        // backgroundColor:"#ccc",
                        border: "1px solid black",
                        padding: "5px 100px 5px 100px",
                        display: "inline-block",
                        boxShadow: "2px 2px 10px #333"

                    }}>
                        <CompanyLogo logoType="PRODUCT_ONLY" fontSize={100}/>
                    </div>
                </a>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '60px'}}>
                    <p>Quickly estimate the number of doctor's offices and/or beds needed for your facility:</p>
                    <iframe id={iFrameID} src={iFrameSrc} width={1000} height="500px" frameBorder="0" scrolling="no"></iframe>
                </div>
            </div>
        </MainLayout>
    )
}

export default AppFastplan